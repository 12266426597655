import * as React from "react"
import "../assets/styles/index.scss"
import Background from "../components/Background/Background"
import {Helmet} from "react-helmet"
import {
    ScrollTrigger,
    Tween
} from "react-gsap"

import heroPlantImg from "../assets/images/hero/plant.png"
import heroPlanetImg from "../assets/images/hero/planet.png"
import shareImg from "../assets/images/meta/share.png"
import wordmarkImg from "../assets/images/branding/wordmark.svg"

const IndexPage = ({location}) => {
    return (
        <main>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Nocturne X</title>
                <meta name="description"
                      content="Enter an otherwordly forest of sound and light on planet Nocturne X in this transformative multi-sensory art experience, where the alien environment reacts to your every touch and movement."/>
                <meta property="og:image" content={shareImg}/>
                <link rel="canonical" href="https://nocturne-x.com"/>
            </Helmet>

            <Background/>

            <a href="https://nocturne-x.com">
                <div className="Hero">
                    <div className="Hero__graphics">
                        <ScrollTrigger
                            start="top 20%"
                            scrub={0.5}
                            // Uncomment below to see dev markers
                            // markers
                        >
                            <Tween
                                to={{
                                    x: '100px',
                                    opacity: '0',
                                }}
                            >
                                <img
                                    src={heroPlantImg}
                                    alt="Plant asteroid artwork"
                                    className="Hero__graphic Hero__graphics-plant"
                                />
                            </Tween>
                            <Tween
                                to={{
                                    x: '-100px',
                                    opacity: '0',
                                }}
                            >
                                <img
                                    src={heroPlanetImg}
                                    alt="Planet artwork"
                                    className="Hero__graphic Hero__graphics-planet"
                                />
                            </Tween>
                        </ScrollTrigger>
                    </div>
                    <img
                        src={wordmarkImg}
                        alt="Nocturne X Logo"
                        className="Img__wordmark"
                    />
                </div>
            </a>
        </main>
    )
}

export default IndexPage
